import { useState } from 'react'

export default function useTokenTime() {
  const getTokenTime = () => {
    const tokenTimeString = localStorage.getItem('tokenTime')
    const userTokenTime = JSON.parse(tokenTimeString)
    return userTokenTime
  }

  const [tokenTime, setTokenTime] = useState(getTokenTime())

  const saveTokenTime = (userTokenTime) => {
    localStorage.setItem('tokenTime', JSON.stringify(userTokenTime))
    setTokenTime(userTokenTime)
  }

  return {
    setTokenTime: saveTokenTime,
    tokenTime,
  }
}
