import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import './scss/style.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'
import { AdminProvider } from './context/adminContext'
import 'react-phone-input-2/lib/style.css'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <AdminProvider>
      <App />
    </AdminProvider>
  </Provider>,
)
