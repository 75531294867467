import { useState } from 'react'

export default function useCurrentUser() {
  const getCurrentUser = () => {
    const userString = localStorage.getItem('currentUser')
    const userData = JSON.parse(userString)
    return userData
  }

  const [currentUser, setCurrentUser] = useState(getCurrentUser())

  const saveCurrentUser = (currentUser) => {
    localStorage.setItem('currentUser', JSON.stringify(currentUser))
    setCurrentUser(currentUser)
  }

  return {
    setCurrentUser: saveCurrentUser,
    currentUser,
  }
}
