import axios from 'axios'
import { ClearAppToken } from './clear_token'

const logoutCall = (
  postBody,
  postHeaders,
  setToken,
  setTokenTime,
  setCurrentUser,
  setCurrentUserRole,
  setCurrentOrganisation,
) => {
  axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_V3_URL}/auth/logout/`,
    headers: postHeaders,
    data: postBody,
  })
    .then((response) => {
      if (response.status == 205)
        ClearAppToken(
          setToken,
          setTokenTime,
          setCurrentUser,
          setCurrentUserRole,
          setCurrentOrganisation,
        )
    })
    .catch((error) => console.log(error))
}

const AdminLogout = (
  token,
  setToken,
  setTokenTime,
  setCurrentUser,
  setCurrentUserRole,
  setCurrentOrganisation,
) => {
  const postBody = {
    refresh_token: token.refresh,
  }

  const postHeaders = {
    Authorization: `Bearer ${token.access}`,
    accept: 'application/json',
    'Content-Type': 'application/json',
    'device-type': 'web',
  }

  logoutCall(
    postBody,
    postHeaders,
    setToken,
    setTokenTime,
    setCurrentUser,
    setCurrentUserRole,
    setCurrentOrganisation,
  )
}

export default AdminLogout
