import { Backdrop, Button, Paper } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AdminContext } from 'src/context/adminContext'
import AdminLogout from './methods/logout'
import { ClearAppToken } from './methods/clear_token'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/common/login/Login'))

function App() {
  const {
    token,
    setToken,
    setTokenTime,
    currentUser,
    setCurrentUser,
    setCurrentUserRole,
    setCurrentOrganisation,
  } = useContext(AdminContext)

  // handling 403 Unauthorized Access
  const [showShowUnauthorizedModal, setShowUnauthorizedModal] = useState(false)
  const [showSessionExpired, setShowSessionExpired] = useState(false)
  const [showSessionExpiredMessage, setShowSessionExpiredMessage] = useState('')

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      if (error.response.status === 403 && error.response.data.error_code !== 20027) {
        setShowUnauthorizedModal(true)
      } else if (error.response.data.error_code === 20027) {
        setShowUnauthorizedModal(true)
        setShowSessionExpired(true)
        setShowSessionExpiredMessage(error.response.data.message)
      }
      return Promise.reject(error)
    },
  )

  return (
    <BrowserRouter>
      <UnauthorizedModal
        showShowUnauthorizedModal={showShowUnauthorizedModal}
        setShowUnauthorizedModal={setShowUnauthorizedModal}
        showSessionExpired={showSessionExpired}
        showSessionExpiredMessage={showSessionExpiredMessage}
        setShowSessionExpired={setShowSessionExpired}
        AdminLogout={AdminLogout}
        ClearAppToken={ClearAppToken}
        token={token}
        setToken={setToken}
        setTokenTime={setTokenTime}
        setCurrentUser={setCurrentUser}
        setCurrentUserRole={setCurrentUserRole}
        setCurrentOrganisation={setCurrentOrganisation}
      />
      <Routes>
        <Route
          exact
          path="/login"
          name="Login Page"
          element={token ? <RedirectHelper to="/" /> : <Login />}
        />
        <Route path="*" element={token ? <DefaultLayout /> : <RedirectHelper to="/login" />} />
      </Routes>
    </BrowserRouter>
  )
}

export const RedirectHelper = ({ to }) => {
  const prevRoute = useLocation()
  return <Navigate to={to} state={prevRoute} replace />
}

export default App

export const UnauthorizedModal = (props) => {
  const {
    showShowUnauthorizedModal,
    setShowUnauthorizedModal,
    showSessionExpired,
    showSessionExpiredMessage,
    setShowSessionExpired,
    AdminLogout,
    ClearAppToken,
    token,
    setToken,
    setTokenTime,
    setCurrentUser,
    setCurrentUserRole,
    setCurrentOrganisation,
  } = props
  return (
    <Backdrop
      open={showShowUnauthorizedModal}
      style={{
        zIndex: 2000,
        position: 'absolute',
        borderRadius: 6,
      }}
      sx={{ opacity: '50%' }}
    >
      <Paper
        style={{ width: 400, height: 250 }}
        elevation={2}
        className="d-flex flex-wrap align-items-center"
      >
        <div className="d-flex row p-0 gx-0 w-100 flex-wrap align-content-center">
          <div
            style={{
              padding: '10px 0px 10px 25px',
              borderBottom: '1px solid #BCBCBC',
            }}
            className="d-flex justify-content-center"
          >
            <p
              className="m-0 d-flex flex-wrap align-items-center"
              style={{
                gap: 14,
                color: '#4F4F4F',
                fontFamily: 'Inter',
                fontSize: 28,
                fontWeight: 500,
              }}
            >
              <svg
                style={{ padding: 0, height: 29, width: 29 }}
                xmlns="http://www.w3.org/2000/svg"
                width="66"
                height="60"
                viewBox="0 0 66 60"
                fill="none"
              >
                <path
                  d="M26.1639 0.849609C22.6981 0.849609 19.3742 2.22641 16.9235 4.67712C14.4727 7.12783 13.096 10.4517 13.096 13.9175C13.096 17.3834 14.4727 20.7073 16.9235 23.158C19.3742 25.6087 22.6981 26.9855 26.1639 26.9855C29.6297 26.9855 32.9536 25.6087 35.4043 23.158C37.855 20.7073 39.2318 17.3834 39.2318 13.9175C39.2318 10.4517 37.855 7.12783 35.4043 4.67712C32.9536 2.22641 29.6297 0.849609 26.1639 0.849609ZM50.6663 30.2525C42.4988 30.2525 35.9648 36.7864 35.9648 44.9539C35.9648 53.1214 42.4988 59.6553 50.6663 59.6553C58.8337 59.6553 65.3677 53.1214 65.3677 44.9539C65.3677 36.7864 58.8337 30.2525 50.6663 30.2525ZM26.1639 33.5195C11.7238 33.5195 0.0280151 39.3674 0.0280151 46.5874V53.1214H31.0644C29.986 50.5334 29.4309 47.7575 29.4309 44.9539C29.4449 41.0803 30.5181 37.2843 32.5345 33.9768C30.4763 33.7155 28.3854 33.5195 26.1639 33.5195ZM50.6663 35.153C56.0895 35.153 60.4672 39.5307 60.4672 44.9539C60.4672 46.7834 59.9772 48.4822 59.0951 49.8544L45.7658 36.5251C47.1379 35.643 48.8368 35.153 50.6663 35.153ZM42.2375 40.0534L55.5667 53.3827C54.1946 54.2648 52.4958 54.7549 50.6663 54.7549C45.2431 54.7549 40.8653 50.3771 40.8653 44.9539C40.8653 43.1244 41.3554 41.4256 42.2375 40.0534Z"
                  fill="#FCB37E"
                />
              </svg>
              {showSessionExpired === true ? 'Session Expired' : 'Access denied'}
            </p>
          </div>
          <div
            className="d-flex justify-content-center w-100"
            style={{
              color: '#787878',
              fontFamily: 'Inter',
              fontSize: 16,
              fontWeight: 400,
              marginTop: 20,
              padding: '0 20px',
              textAlign: 'center',
            }}
          >
            {showSessionExpired === true
              ? showSessionExpiredMessage
              : 'Reserved for specific role.'}
          </div>
          <div
            className="d-flex justify-content-center w-100"
            style={{ marginTop: 40, marginBottom: 20 }}
          >
            <Button
              onClick={() => {
                setShowUnauthorizedModal(false)
                setShowSessionExpired(false)
                if (showSessionExpired === true) {
                  ClearAppToken(
                    setToken,
                    setTokenTime,
                    setCurrentUser,
                    setCurrentUserRole,
                    setCurrentOrganisation,
                  )
                } else {
                  Navigate('/')
                }
              }}
              style={{
                padding: '4px 25px',
                backgroundColor: '#FFF',
                border: '1px solid #FCB37E',
                borderRadius: 6,
              }}
            >
              <div
                style={{
                  color: '#FCB37E',
                  fontFamily: 'Inter',
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                OK
              </div>
            </Button>
          </div>
        </div>
      </Paper>
    </Backdrop>
  )
}
