export const ClearAppToken = (
  setToken,
  setTokenTime,
  setCurrentUser,
  setCurrentUserRole,
  setCurrentOrganisation,
) => {
  setToken(null)
  setTokenTime('')
  setCurrentUser(null)
  setCurrentOrganisation(null)
  setCurrentUserRole(null)
  localStorage.removeItem('tokenTime')
  localStorage.removeItem('token')
  localStorage.removeItem('currentUser')
  localStorage.removeItem('currentOrganisation')
  localStorage.removeItem('currentUserRole')
}
