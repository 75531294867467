import axios from 'axios'
import { ClearAppToken } from './clear_token'

const RefreshToken = (token, setToken, setTokenTime) => {
  axios
    .post(
      `${process.env.REACT_APP_API_V3_URL}/auth/refresh`,
      { refresh: token.refresh },
      {
        headers: {
          'device-type': 'web',
        },
      },
    )
    .then((response) => {
      setToken(response.data.data)
    })
    .catch((error) => {
      ClearAppToken(setToken, setTokenTime)
    })
}

export default RefreshToken
