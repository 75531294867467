import { useState } from 'react'

export default function useCurrentOrganisation() {
  const getCurrentOrganisation = () => {
    const ogranisationString = localStorage.getItem('currentOrganisation')
    const currentOrganisation = JSON.parse(ogranisationString)
    return currentOrganisation
  }

  const [currentOrganisation, setCurrentOrganisation] = useState(getCurrentOrganisation)

  const saveCurrentOrganisation = (currentOrganisation) => {
    localStorage.setItem('currentOrganisation', JSON.stringify(currentOrganisation))
    setCurrentOrganisation(currentOrganisation)
  }
  return {
    setCurrentOrganisation: saveCurrentOrganisation,
    currentOrganisation,
  }
}
