import { useState } from 'react'

export default function useCurrentUserRole() {
  const getCurrentUserRole = () => {
    const roleString = localStorage.getItem('currentUserRole')
    const userRole = JSON.parse(roleString)
    return userRole
  }

  const [currentUserRole, setCurrentUserRole] = useState(getCurrentUserRole)

  const saveUserRole = (userRole) => {
    localStorage.setItem('currentUserRole', JSON.stringify(userRole))
    setCurrentUserRole(userRole)
  }
  return {
    setCurrentUserRole: saveUserRole,
    currentUserRole,
  }
}
