import React, { createContext, useEffect, useState } from 'react'
import useToken from 'src/hooks/useToken'
import useTokenTime from 'src/hooks/tokenTime'
import { ClearAppToken } from 'src/methods/clear_token'
import RefreshToken from 'src/methods/refresh_token'
import axios from 'axios'
import useCurrentUser from 'src/hooks/currentUser'
import useCurrentOrganisation from 'src/hooks/currentOrganisation'
import useCurrentUserRole from 'src/hooks/userRole'

export function AdminProvider({ children }) {
  // external hooks
  const { token, setToken } = useToken()
  const { tokenTime, setTokenTime } = useTokenTime()
  const { currentUser, setCurrentUser } = useCurrentUser()
  const { currentUserRole, setCurrentUserRole } = useCurrentUserRole()
  const { currentOrganisation, setCurrentOrganisation } = useCurrentOrganisation()

  // Auto update token
  const checkTokenTime = () => {
    const TimeDiff = (new Date().getTime() - new Date(tokenTime).getTime()) / (1000 * 3600 * 24)
    if (TimeDiff > 14) {
      RefreshToken(token, setToken, setTokenTime)
    }
  }

  // doing all the first time checks
  useEffect(() => {
    if (!!!token) return
    if (!(!!tokenTime && !!currentUser && !!currentUserRole)) {
      ClearAppToken(
        setToken,
        setTokenTime,
        setCurrentUser,
        setCurrentUserRole,
        setCurrentOrganisation,
      )
      return
    }
    checkTokenTime()
  }, [])

  return (
    <AdminContext.Provider
      value={{
        token,
        setToken,
        currentUser,
        setCurrentUser,
        tokenTime,
        setTokenTime,
        currentUserRole,
        setCurrentUserRole,
        currentOrganisation,
        setCurrentOrganisation,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export const AdminContext = createContext()
